import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import history from 'util/History';
import FormUtils from 'util/FormsUtils.js';
import ConfirmLeaveDialog from 'components/common/ConfirmLeaveDialog';
import Wizard from 'components/common/Wizard';
import Routes from 'routing/Routes';

import StepReview from './StepReview';
import StepSubscribe from './StepSubscribe';
import StepAuthorize from './StepAuthorize';
import { StepsContext } from './context/Steps';
import { SidebarContext } from './context/Sidebar';
import { FormDataContext } from './context/FormData';

const O365 = ({ route }) => {
  const {
    availableSteps,
    currentStep,
    isDisabledStep,
    setAvailableStep,
    setCurrentStep,
    setEnabledStep,
  } = useContext(StepsContext);
  const { setFormData } = useContext(FormDataContext);
  const { sidebar, clearSidebar } = useContext(SidebarContext);
  const [dirty, setDirty] = useState(false);
  const [lastStep, setLastStep] = useState(false);

  const handleFieldUpdate = ({ target }, fieldData) => {
    const id = target.name || target.id;
    let value = FormUtils.getValueFromInput(target);

    if (typeof value === 'string') {
      value = value.trim();
    }

    if (!dirty) {
      setDirty(true);
    }

    setFormData(id, { ...fieldData, value });
  };

  const handleSubmit = () => {
    clearSidebar();
    const nextStep = availableSteps.indexOf(currentStep) + 1;

    if (availableSteps[nextStep]) {
      const key = availableSteps[nextStep];

      setEnabledStep(key);
      setCurrentStep(key);
    } else {
      setLastStep(true);
      history.push(Routes.SYSTEM.INPUTS);
    }
  };

  const wizardSteps = [
    {
      key: 'authorize',
      title: 'O365 Connection Configuration',
      component: (<StepAuthorize onSubmit={handleSubmit}
                                 onChange={handleFieldUpdate} />),
      disabled: isDisabledStep('authorize'),
    },
    {
      key: 'subscribe',
      title: 'O365 Content Subscription',
      component: (<StepSubscribe onSubmit={handleSubmit}
                                 onChange={handleFieldUpdate} />),
      disabled: isDisabledStep('subscribe'),
    },
    {
      key: 'review',
      title: 'O365 Final Review',
      component: (<StepReview onSubmit={handleSubmit}
                              onChange={handleFieldUpdate} />),
      disabled: isDisabledStep('review'),
    },

  ];

  useEffect(() => {
    if (availableSteps.length === 0) {
      setAvailableStep(wizardSteps.map((step) => step.key));
    }
  }, []);

  return (
    <>
      {dirty && !lastStep && <ConfirmLeaveDialog route={route} question="Are you sure? Your new Input will not be created." />}
      <Wizard steps={wizardSteps}
              activeStep={currentStep}
              onStepChange={setCurrentStep}
              horizontal
              justified
              hidePreviousNextButtons>
        {sidebar}
      </Wizard>
    </>
  );
};

O365.propTypes = {
  route: PropTypes.object.isRequired,
};

export default withRouter(O365);
