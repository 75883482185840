import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from 'components/common/PageHeader';

import { SidebarProvider } from './context/Sidebar';
import { FormDataProvider } from './context/FormData';
import { StepsProvider } from './context/Steps';
import { ApiProvider } from './context/Api';
import { AdvancedOptionsProvider } from './context/AdvancedOptions';
import O365 from './O365';
import INITIAL_FORMDATA from './_initialFormData';

const O365App = ({ params: { step }, route }) => {
  return (
    <ApiProvider>
      <StepsProvider>
        <FormDataProvider initialFormData={INITIAL_FORMDATA}>
          <SidebarProvider>
            <AdvancedOptionsProvider>
              <PageHeader title="Office 365 Integrations">
                <span>This feature retrieves log records from Office 365.</span>
                <p>You have <a href="https://portal.azure.com/#blade/" rel="noopener noreferrer" target="_blank">Azure AD</a>, <a href="https://www.office.com/" rel="noopener noreferrer" target="_blank"> Office 365</a> subscriptions and have turned on <a href="https://protection.office.com/unifiedauditlog" rel="noopener noreferrer" target="_blank">unified logging.</a></p>
              </PageHeader>

              <O365 wizardStep={step} route={route} />
            </AdvancedOptionsProvider>
          </SidebarProvider>
        </FormDataProvider>
      </StepsProvider>
    </ApiProvider>
  );
};

O365App.propTypes = {
  params: PropTypes.shape({
    step: PropTypes.string,
  }).isRequired,
  route: PropTypes.object.isRequired,
};

export default O365App;
