import 'webpack-entry';
import { PluginManifest, PluginStore } from 'graylog-web-plugin/plugin';

import Routes from './o365/common/Routes';
import O365InputConfiguration from './o365/O365InputConfiguration';
import o365App from './o365/O365App';

import packageJson from '../../package.json';

const manifest = new PluginManifest(packageJson, {
  routes: [
    { path: Routes.INTEGRATIONS.O365.ACTIVITYAPI.index, component: o365App },
  ],
  inputConfiguration: [
    {
      type: 'org.graylog.enterprise.integrations.office365.Office365Input',
      component: O365InputConfiguration,
    },
  ],

});

PluginStore.register(manifest);
